<template>
    <div
        :id="modalPindah"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Pindah</h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        @click="close()"
                    ></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <!-- <label for="inputPilihDoctor">{{this.$i18n.t("formInternal.select")}}</label> -->
                            <label for="inputPilihDoctor"
                                >Pilih Tujuan Rujukan</label
                            >
                            <vue-multi-select
                                :options="getCount"
                                v-model="selectReserve"
                                :name="'doktor'"
                                :validation="'required'"
                                :validationName="'Doktor'"
                            ></vue-multi-select>
                            <br />
                            <div v-if="tampilkanOpsi">
                                <label for="inputPilihOpsi"
                                    >Antrian Pengguna Aplikasi Mobile, tetap
                                    cetak tiket di printer ?</label
                                >
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        name="Tidak"
                                        id="Tidak"
                                        :value="false"
                                        v-model="opsiPrint"
                                    />
                                    <label class="form-check-label" for="Tidak">
                                        Tidak (default)
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input
                                        class="form-check-input"
                                        type="radio"
                                        name="Ya"
                                        id="Ya"
                                        :value="true"
                                        v-model="opsiPrint"
                                    />
                                    <label class="form-check-label" for="Ya">
                                        Ya
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button
                        type="submit"
                        class="btn btn-primary"
                        @click.prevent="submit()"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { RepositoryFactory } from "./../../../../repositories/RepositoryFactory";
import SweetAlertTemplate from "./../../../_general/SweetAlert";
import BlockUI from "./../../../_js/BlockUI";
import vueMultiSelect from "./../../../_select/VueMultiSelect";
import DivToPrint from "./../../../_general/DivToPrint";
const SweetAlert = Vue.extend(SweetAlertTemplate);
const blockUI = new BlockUI();

const QueueResourceRepository = RepositoryFactory.get("queueResource");
const QueueUnitRepository = RepositoryFactory.get("queueUnit");
const ServerTimestampRepository = RepositoryFactory.get("serverTimestamp");

import { Printd } from "printd";
import qz from "qz-tray";

export default {
    data() {
        return {
            listReservasi: [],
            selectReserve: null,
            tampilkanOpsi: false,
            opsiPrint: true
        };
    },
    computed: {
        ...mapState("DoctorCallerStore", {
            showModalPindah: state => state.showModalPindah,
            selectedDate: state => state.selectedDate
        }),
        ...mapState("CallerStore", {
            queueDevice: state => state.queueDevice,
            printingOption: state => state.printingOption,
            defaultPrinter: state => state.defaultPrinter
        }),
        getCount() {
            let arr = [];
            let list = this.listReservasi.forEach(el => {
                let totalAntri = helpers.countAntrian(el);

                let jadwal = "";
                if (el.jadwal) {
                    jadwal = `(${el.jadwal.jamMulai} - ${
                        el.jadwal.jamSelesai
                    })`;
                }
                arr.push(
                    `${el.displayName1} ${
                        el.displayName2
                    } ${jadwal} (${totalAntri})`
                );
            });
            return arr;
        }
    },
    components: {
        vueMultiSelect
    },
    model: {
        prop: "value",
        event: "change"
    },
    props: {
        modalPindah: { type: String, default: "pindahModal" },
        value: Object
    },
    methods: {
        ...mapMutations({
            setShowModalPindah: "DoctorCallerStore/SET_SHOW_MODAL_PINDAH",
            setSelectedQueue: "UnitCallerStore/SET_SELECTED_QUEUE"
        }),
        ...mapActions({
            postDoneQueue: "UnitCallerStore/POST_DONE_QUEUE",
            SOCK_MSG: "DoctorAppointmentStore/SOCK_MSG"
        }),
        submit() {
            var vx = this;
            if (this.selectReserve) {
                let instance = new SweetAlert().showConfirm({
                    swalTitle: "Apakah Anda yakin?",
                    swalText: "Data Akan Diperbarui.",
                    swalType: "info",
                    onConfirmButton: function() {
                        if (vx.selectReserve) {
                            let i;
                            vx.getCount.forEach(function(x, index) {
                                if (x === vx.selectReserve) {
                                    i = index;
                                }
                            });
                            vx.selectReserve = vx.listReservasi[i];
                        }

                        vx.createQueue();
                        vx.close();
                    },

                    onCancelButton: function() {
                        blockUI.unblockModal();
                    }
                });
            } else {
                toastr.error("Please Select");
            }
        },
        async createQueue() {
            let vx = this;
            let payloadPindah = {
                note: vx.value.note,
                prevQueueTargetUnitId: vx.selectReserve.id,
                queueId: vx.value.queue ? vx.value.queue.id : vx.value.id,
                uniqueId: vx.queueDevice.uniqueId
            };
            let response = await QueueResourceRepository.postDoneResource(
                payloadPindah
            );
            if (response) {
                let instance = new SweetAlert().showInfo({
                    swalTitle: "Berhasil!",
                    swalText: `Antrian baru berhasil ditambahkan.`,
                    swalType: "success",
                    onConfirmButton: function() {
                        blockUI.unblockModal();
                    }
                });
                this.setSelectedQueue(null);
                if (this.opsiPrint) {
                    let payload = {
                        printD: false
                    };
                    if (sessionStorage.viewCaller == "DOCTOR_CALLER") {
                        vx.$parent.$parent.detailPrint.displayName1 =
                            vx.selectReserve.displayName1;
                        vx.$parent.$parent.detailPrint.displayName2 =
                            vx.selectReserve.displayName2;
                        vx.$parent.$parent.detailPrint.queueNo =
                            response.data.nextQueueNoFormatted;
                        vx.$parent.$parent.detailPrint.content = `{"content": "${
                            response.data.nextQueueEncryptedId
                        }","type":"antrian"}`;

                        await ServerTimestampRepository.get().then(response => {
                            var dateFormattedId = moment(response.data).format(
                                "dddd, D MMMM YYYY HH:mm:ss"
                            );
                            vx.$parent.$parent.detailPrint.date = dateFormattedId;
                        });

                        if (vx.printingOption == "DIALOG") {
                            payload.printD = true;
                        }
                        vx.$parent.$parent.$refs.detailPrint.printSelectedQueue(payload);
                    } else {
                        vx.$parent.detailPrint.displayName1 =
                            vx.selectReserve.displayName1;
                        vx.$parent.detailPrint.displayName2 =
                            vx.selectReserve.displayName2;
                        vx.$parent.detailPrint.queueNo =
                            response.data.nextQueueNoFormatted;
                        vx.$parent.detailPrint.content = `{"content": "${
                            response.data.nextQueueEncryptedId
                        }","type":"antrian"}`;

                        await ServerTimestampRepository.get().then(response => {
                            var dateFormattedId = moment(response.data).format(
                                "dddd, D MMMM YYYY HH:mm:ss"
                            );
                            vx.$parent.detailPrint.date = dateFormattedId;
                        });
                        if (vx.printingOption == "DIALOG") {
                            payload.printD = true;
                        }
                        vx.$parent.$refs.detailPrint.printSelectedQueue(payload);
//                      const printDiv = document.getElementById('divToPrint')
                    }
                }
            } else {
                toastr.error(message);
            }
        },
        async getCountReservasi() {
            let hari = [
                "SUNDAY",
                "MONDAY",
                "TUESDAY",
                "WEDNESDAY",
                "THURSDAY",
                "FRIDAY",
                "SATURDAY"
            ];

            try {
                const { data } = await new Promise((resolve, reject) => {
                    QueueUnitRepository.geQueueUnitSlot(
                        {
                            dayOfWeeks:
                                hari[new Date(this.selectedDate).getDay()],
                            tanggalKunjunganMulai: `${this.selectedDate}`,
                            tanggalKunjunganSelesai: `${this.selectedDate}`,
                            tanggalJadwalMulai: `${this.selectedDate}`,
                            tanggalJadwalSelesai: `${this.selectedDate}`,
                            slotTypes: "APPOINTMENT"
                            // "size": 1000
                        },
                        resolve,
                        reject
                    );
                });

                this.listReservasi = data;
            } catch (err) {
                toastr.error("error get data");
            }
        },
        close() {
            $("#" + this.modalPindah).modal("hide");
            this.setShowModalPindah(false);
        }
    },
    mounted() {
        $("#" + this.modalPindah).modal();
        this.wizard = new KTWizard("kt_wizard_v1", {
            startStep: 1
        });
        if (this.value.queue) {
            if (this.value.queue.queueType == "AUTO") {
                this.tampilkanOpsi = true;
                this.opsiPrint = false;
            }
        }
        this.getCountReservasi();
    }
};
</script>

<style lang="scss" scoped></style>
